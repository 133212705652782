import React from 'react';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { NewsContainer } from './styled.components';
import {
  FlexColumnContainer,
  H2Style,
  H4Style,
  LogosContainer
} from '../../styled.components';
import { Trans } from 'react-i18next';
import { StaticImage } from 'gatsby-plugin-image';
const NewsSection = () => {
  const { t } = useTranslation();

  const items = [
    {
      img: (
        <StaticImage
          className="image"
          src="../../../../images/for-companies/news/1.webp"
          alt="image"
          placeholder="none"
          loading="lazy"
        />
      )
    },
    {
      img: (
        <StaticImage
          className="image"
          src="../../../../images/for-companies/news/2.webp"
          alt="image"
          placeholder="none"
          loading="lazy"
        />
      )
    },
    {
      img: (
        <StaticImage
          className="image"
          src="../../../../images/for-companies/news/3.webp"
          alt="image"
          placeholder="none"
          loading="lazy"
        />
      )
    },
    {
      img: (
        <StaticImage
          className="image"
          src="../../../../images/for-companies/news/4.webp"
          alt="image"
          placeholder="none"
          loading="lazy"
        />
      )
    },
    {
      img: (
        <StaticImage
          className="image"
          src="../../../../images/for-companies/news/5.webp"
          alt="image"
          placeholder="none"
          loading="lazy"
        />
      )
    },
    {
      img: (
        <StaticImage
          className="image"
          src="../../../../images/for-companies/news/6.webp"
          alt="image"
          placeholder="none"
          loading="lazy"
        />
      )
    },
    {
      img: (
        <StaticImage
          className="image"
          src="../../../../images/for-companies/news/7.webp"
          alt="image"
          placeholder="none"
          loading="lazy"
        />
      )
    },
    {
      img: (
        <StaticImage
          className="image"
          src="../../../../images/for-companies/news/8.webp"
          alt="image"
          placeholder="none"
          loading="lazy"
        />
      )
    },
    {
      img: (
        <StaticImage
          className="image"
          src="../../../../images/for-companies/news/9.webp"
          alt="image"
          placeholder="none"
          loading="lazy"
        />
      )
    },
    {
      img: (
        <StaticImage
          className="image"
          src="../../../../images/for-companies/news/10.webp"
          alt="image"
          placeholder="none"
          loading="lazy"
        />
      )
    },
    {
      img: (
        <StaticImage
          className="image"
          src="../../../../images/for-companies/news/11.webp"
          alt="image"
          placeholder="none"
          loading="lazy"
        />
      )
    },
    {
      img: (
        <StaticImage
          className="image"
          src="../../../../images/for-companies/news/12.webp"
          alt="image"
          placeholder="none"
          loading="lazy"
        />
      )
    }
  ];

  const renderItems = items?.map((item, index) => {
    return <div key={index}>{item.img}</div>;
  });

  return (
    <>
      <NewsContainer>
        <div className="container">
          <FlexColumnContainer gap="8px">
            <H4Style textTransform="uppercase">{t`for-companies.news-title`}</H4Style>
            <H2Style>
              <Trans
                i18nKey="for-companies.news-title2"
                components={{
                  b: <b></b>
                }}
              />
            </H2Style>
          </FlexColumnContainer>
          <LogosContainer>{renderItems}</LogosContainer>
        </div>
      </NewsContainer>
    </>
  );
};

export default NewsSection;
