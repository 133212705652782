import styled from 'styled-components';

export const OpinionsSliderContainer = styled.div`
  width: ${(props) => props.width || '100%'};
  max-width: 900px;
  box-sizing: border-box;
  position: relative;
  margin: auto;

  .slick-slider,
  .slick-slider .slick-slide div {
    padding: 0;
    /* border-radius: 16px; */
  }

  .slick-slider .slick-slide {
    /* max-width: 280px; */
  }

  .slick-list {
    margin: auto;
  }

  .slick-list .slick-track > div,
  .slick-list .slick-track > div > div {
    height: 650px;
    display: grid;
    margin: auto;
  }

  .slick-list .slick-track div {
    /* height: 320px; */
  }

  .slick-arrow {
    display: flex;
    width: 24px;
    height: 24px;
    padding: 8px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 5px;
    top: 95.5%;
    transform: none;
    z-index: 2;
    transition: all 0.2s ease-out;
  }

  .slick-prev {
    left: 5px;
  }

  .slick-next {
    left: 120px;
  }

  .slick-arrow:hover {
    background: var(--White, rgba(255, 255, 255, 0.9));
  }

  .slick-arrow:hover path {
    stroke: #231b32;
  }

  .slider-pagination {
    position: relative;
    color: var(--White, rgba(255, 255, 255, 0.9));
    font-family: Raleway;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-left: 70px;
    top: -12px;
  }

  @media only screen and (max-width: 1330px) {
    max-width: 600px;

    .slider-pagination {
      display: none !important;
    }
  }

  @media only screen and (max-width: 976px) {
    max-width: 300px;
  }

  @media only screen and (max-width: 700px) {
    .slick-next {
      left: auto;
      right: 5px;
    }
  }
`;

export const OpinionsSlide = styled.div`
  display: flex !important;
  padding: 24px !important;
  max-width: 280px;
  height: 600px;
  box-sizing: border-box;
  flex-direction: column;
  align-items: flex-start;
  gap: 17px;
  /* flex: 1 0 0; */
  border-radius: 16px;
  background: var(--Outsource2-Dark, #100722);
  box-shadow: 4px 4px 20px 0px rgba(16, 7, 34, 0.25);
  /* margin: 0 10px; */
  .option-slide-person {
    margin-top: auto;
  }
`;
