import styled from 'styled-components';

export const AdventagesContainer = styled.div`
  position: relative;
  overflow-x: hidden;
  .container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .withoutOutsource2-flex-container {
    position: relative;
    padding-bottom: 60px;
  }

  .image-circle1 {
    position: absolute !important;
    top: 60px;
    left: 0;
    z-index: -1;
  }

  .image-circle2 {
    position: absolute !important;
    bottom: 50px;
    left: -100px;
    z-index: -1;
  }

  .image-circle3 {
    position: absolute !important;
    top: 230px;
    right: -200px;
    z-index: -1;
  }

  @media only screen and (max-width: 1100px) {
    .image-circle1,
    .image-circle2,
    .image-circle3 {
      display: none;
    }
  }

  @media only screen and (max-width: 700px) {
    .withoutOutsource2-flex-container {
      flex-direction: column;
    }
  }
`;

export const SwitchingButtonsContainer = styled.div`
  display: flex;
  align-items: flex-start;
  border-radius: 8px;
  overflow: hidden;
  border: 2px solid var(--UI-Grey-blue, #6717be);
`;

export const SwitchButton = styled.button`
  display: flex;
  padding: 8px 24px;
  align-items: flex-start;
  gap: 10px;
  height: 55px;
  box-sizing: border-box;
  border: 0px;
  background-color: rgba(0, 0, 0, 0);
  cursor: pointer;
  transition: all 0.3s ease-in-out;

  color: var(--UI-Blue-Tint, #f5fafd);
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
  letter-spacing: 0.8px;
  align-items: center;

  &.checked,
  &:hover {
    background: var(--UI-Blue, #6717be);
  }
`;

export const SwitchingDataContainer = styled.div`
  display: flex;
  padding: 40px 0px 60px 0px;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;

  @media only screen and (max-width: 1300px) {
    flex-direction: column;

    > :nth-child(1) {
      order: 1;
    }
    > :nth-child(3) {
      order: 2;
    }
    > :nth-child(2) {
      order: 3;
    }
  }
`;

export const ImageContainer = styled.div`
  flex: 1 0 0;

  img {
    max-height: 620px;
  }

  @media only screen and (max-width: 1300px) {
    margin: auto;
  }
`;

export const OutsourceTile = styled.div`
  display: flex;
  padding: 8px;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
  border-radius: 16px;
  background: var(--Outsource2-Alt-Sec, #231b32);
  box-shadow: 0px 0px 20px 0px rgba(15, 83, 115, 0.2);
  width: 285px;

  svg {
    display: flex;
    padding: 8px;
    align-items: center;
    gap: 10px;
    border-radius: 8px;
    background: linear-gradient(180deg, #6717be 0%, rgba(103, 23, 190, 0) 100%);
    flex-shrink: 0;
  }

  @media only screen and (max-width: 1300px) {
    margin: auto;
  }
`;
