import { useTranslation } from 'gatsby-plugin-react-i18next';
import React, { useState } from 'react';
import Slider from 'react-slick';
import {
  BottomCaseStudy,
  CaseStudiesSlide,
  CaseStudiesSliderContainer,
  TagContainer
} from './styled.components';
import {
  FlexContainer,
  H3Style,
  ParagraphSmallStyle
} from '../../../styled.components';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

const CaseStudiesSlider = ({
  slides,
  totalSlidesStateProp = 8,
  isSliderAutoplay = true,
  speed = 1000,
  autoplaySpeed = 4000,
  openModal
}) => {
  const { t } = useTranslation();
  function SampleNextArrow(props) {
    const { className, onClick } = props;
    return (
      <svg
        className={className}
        onClick={onClick}
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none">
        <path
          d="M19 12H5"
          stroke="#FFFFFF"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M13 18L19 12"
          stroke="#FFFFFF"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M13 6L19 12"
          stroke="#FFFFFF"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    );
  }

  function SamplePrevArrow(props) {
    const { className, onClick } = props;
    return (
      <svg
        className={className}
        onClick={onClick}
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="#FFFFFF">
        <path
          d="M5 12H19"
          stroke="#FFFFFF"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M11 6L5 12"
          stroke="#FFFFFF"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M11 18L5 12"
          stroke="#FFFFFF"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    );
  }
  const [totalSlidesState, setTotalSlidesState] =
    useState(totalSlidesStateProp);
  const totalSlides = totalSlidesState;
  const slidesToShow = slides.length > 2 ? 3 : slides.length;
  console.log(slidesToShow);
  const settings = {
    // dots: true,
    className: 'center',
    // centerPadding: '60px',
    centerMode: true,
    infinite: true,
    speed: speed,
    slidesToShow: slidesToShow,
    slidesToScroll: 1,
    autoplay: isSliderAutoplay,
    autoplaySpeed: autoplaySpeed,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    beforeChange: (currentSlide, nextSlide) => {
      setCurrentPage(Math.ceil(nextSlide / slidesToShow) + 1);
    },
    responsive: [
      {
        breakpoint: 1100,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };

  const totalPages = Math.ceil(totalSlides / slidesToShow);
  const [currentPage, setCurrentPage] = React.useState(1);
  return (
    <>
      <CaseStudiesSliderContainer>
        <Slider {...settings}>
          {slides.map((item, index) => (
            <div>
              <CaseStudiesSlide
                onClick={() => openModal(item)}
                key={index}
                className={slides.length > 3 && 'case-study-shade'}>
                <GatsbyImage
                  alt={item.node.title}
                  placeholder={item.node.title}
                  className="image"
                  image={getImage(item.node.headerImage.gatsbyImageData)}
                />
                <BottomCaseStudy>
                  <H3Style>{item.node.title}</H3Style>
                  <FlexContainer
                    alignItems="flex-start"
                    gap="4px"
                    className="tag-container">
                    {item.node.tags.map((tag, index) => (
                      <TagContainer key={index}>{tag}</TagContainer>
                    ))}
                  </FlexContainer>
                  <ParagraphSmallStyle opacity={0.7}>
                    {item.node.description.description.length > 250
                      ? item.node.description.description.substring(0, 250) +
                        '...'
                      : item.node.description.description}
                  </ParagraphSmallStyle>
                </BottomCaseStudy>
                <a>{t`for-companies.cs-know-more`}</a>
              </CaseStudiesSlide>
            </div>
          ))}
        </Slider>
        {/* <div className="slider-pagination">
          {currentPage}/{totalPages}
        </div> */}
      </CaseStudiesSliderContainer>
    </>
  );
};

export default CaseStudiesSlider;
