import styled from 'styled-components';

export const HaveDoubtsContainer = styled.div`
  background: var(--Outsource2-Alt-Sec, #231b32);
  position: relative;
  .container {
    display: flex;
    width: 100%;

    @media only screen and (max-width: 1300px) {
      padding-bottom: 450px;
      width: fit-content;
    }
  }
`;

export const LeftDoubtsContainer = styled.div`
  display: flex;
  max-width: 684px;
  box-sizing: border-box;
  padding: 80px 0px;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
`;

export const RelativeContainer = styled.div`
  overflow: hidden;
  display: inline-flex;
  margin-top: auto;
  position: absolute;
  bottom: 0;
  right: 0;
  width: 800px;
  box-sizing: border-box;

  .kacper-image {
    z-index: 1;
    margin-left: auto;
    margin-right: auto;
  }

  .kacper-image img {
    width: 350px;
    margin-top: 170%;
  }

  .circle-image {
    position: absolute !important;
  }

  .circle1 {
    bottom: 120px;
  }

  .circle2 {
    bottom: 350px;
    right: 240px;
  }

  .circle3 {
    bottom: -240px;
    right: -120px;
  }

  @media only screen and (max-width: 1700px) {
    .circle1 {
      display: none !important;
    }
  }

  @media only screen and (max-width: 1300px) {
    .circle1 {
      display: block !important;
    }
  }

  @media only screen and (max-width: 800px) {
    .circle1,
    .circle2,
    .circle3 {
      display: none !important;
    }

    .kacper-image {
      margin-right: 0px;
    }

    .kacper-image img {
      width: 300px;
      /* margin-right: 0px; */
    }
  }
`;
