import React from 'react';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import {
  HaveDoubtsContainer,
  LeftDoubtsContainer,
  RelativeContainer
} from './styled.components';
import {
  ButtonContainer,
  H2Style,
  H3Style,
  PurpleButton,
  TransparentButton
} from '../../styled.components';
import { Trans } from 'react-i18next';
import { StaticImage } from 'gatsby-plugin-image';
import '../../../../images/for-companies/hb/kacper_hb.webp';
const HaveDoubtsSection = () => {
  const { t } = useTranslation();

  return (
    <>
      <HaveDoubtsContainer>
        <div className="container">
          <LeftDoubtsContainer>
            <H2Style>
              <Trans
                i18nKey="for-companies.hd-title"
                components={{
                  b: <b></b>
                }}
              />
            </H2Style>
            <H3Style
              fontWeight={400}
              opacity={0.7}>{t`for-companies.hd-title2`}</H3Style>
            <ButtonContainer>
              <PurpleButton
                as="a"
                target="_blank"
                rel="noreferrer"
                href="https://calendar.app.google/8aEj3y4G6Pbj4yVq6">{t`for-companies.hd-meeting`}</PurpleButton>
              <TransparentButton
                as="a"
                href="#contact">{t`for-companies.hd-call-us`}</TransparentButton>
            </ButtonContainer>
          </LeftDoubtsContainer>
          <RelativeContainer>
            <StaticImage
              className="circle-image circle1"
              src="../../../../images/for-companies/hb/circle1.webp"
              alt="image"
              placeholder="none"
              loading="lazy"
            />
            <StaticImage
              className="circle-image circle2"
              src="../../../../images/for-companies/hb/circle2.webp"
              alt="image"
              placeholder="none"
              loading="lazy"
            />
            <StaticImage
              className="circle-image circle3"
              src="../../../../images/for-companies/hb/circle3.webp"
              alt="image"
              placeholder="none"
              loading="lazy"
            />
            <StaticImage
              className="kacper-image"
              src="../../../../images/for-companies/hb/kacper_hb.webp"
              alt="image"
              placeholder="none"
              loading="lazy"
            />
          </RelativeContainer>
        </div>
      </HaveDoubtsContainer>
    </>
  );
};

export default HaveDoubtsSection;
