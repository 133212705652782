import React from 'react';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import {
  OpinionsContainer,
  StarOpinionDiv,
  StarOpinionInnerDiv,
  StarOpinionTile
} from './styled.components';
import {
  FlexColumnContainer,
  FlexContainer,
  H2Style,
  H4Style,
  ParagraphLargeStyle
} from '../../styled.components';
import { Trans } from 'react-i18next';
import { StaticImage } from 'gatsby-plugin-image';
import OpinionsSlider from './components';

const OpinionsSection = () => {
  const { t } = useTranslation();

  const items = [
    {
      description: t`for-companies.opinions-item-description1`,
      image: (
        <StaticImage
          className="person-image"
          src="../../../../images/for-companies/opinions/1.jpeg"
          alt="image"
          placeholder="none"
          loading="lazy"
        />
      ),
      name: t`for-companies.opinions-item-name1`,
      position: t`for-companies.opinions-item-position1`
    },
    {
      description: t`for-companies.opinions-item-description2`,
      image: (
        <StaticImage
          className="person-image"
          src="../../../../images/for-companies/opinions/2.jpg"
          alt="image"
          placeholder="none"
          loading="lazy"
        />
      ),
      name: t`for-companies.opinions-item-name2`,
      position: t`for-companies.opinions-item-position2`
    },
    {
      description: t`for-companies.opinions-item-description3`,
      image: (
        <StaticImage
          className="person-image"
          src="../../../../images/for-companies/opinions/3.png"
          alt="image"
          placeholder="none"
          loading="lazy"
        />
      ),
      name: t`for-companies.opinions-item-name3`,
      position: t`for-companies.opinions-item-position3`
    },
    {
      description: t`for-companies.opinions-item-description4`,
      image: (
        <StaticImage
          className="person-image"
          src="../../../../images/for-companies/opinions/4.jpg"
          alt="image"
          placeholder="none"
          loading="lazy"
        />
      ),
      name: t`for-companies.opinions-item-name4`,
      position: t`for-companies.opinions-item-position4`
    },
    {
      description: t`for-companies.opinions-item-description5`,
      image: (
        <StaticImage
          className="person-image"
          src="../../../../images/for-companies/opinions/5.png"
          alt="image"
          placeholder="none"
          loading="lazy"
        />
      ),
      name: t`for-companies.opinions-item-name5`,
      position: t`for-companies.opinions-item-position5`
    },
    {
      description: t`for-companies.opinions-item-description6`,
      image: (
        <StaticImage
          className="person-image"
          src="../../../../images/for-companies/opinions/6.png"
          alt="image"
          placeholder="none"
          loading="lazy"
        />
      ),
      name: t`for-companies.opinions-item-name6`,
      position: t`for-companies.opinions-item-position6`
    }
  ];

  return (
    <>
      <OpinionsContainer>
        <StaticImage
          className="image-circle1"
          src="../../../../images/for-companies/opinions/circle1.webp"
          alt="image"
          placeholder="none"
          loading="lazy"
        />
        <StaticImage
          className="image-circle2"
          src="../../../../images/for-companies/opinions/circle2.webp"
          alt="image"
          placeholder="none"
          loading="lazy"
        />
        <div className="container">
          <FlexColumnContainer alignItems="flex-start" gap="8px">
            <H4Style textTransform="uppercase">{t`for-companies.o-title`}</H4Style>
            <H2Style>
              <Trans
                i18nKey="for-companies.o-title2"
                components={{
                  b: <b></b>
                }}
              />
            </H2Style>
          </FlexColumnContainer>
          <FlexContainer
            alignItems="flex-start"
            gap="20px"
            className="flex-opinions">
            <StarOpinionTile>
              <StarOpinionDiv>
                <ParagraphLargeStyle lineHeight="150%">{t`for-companies.o-star-opinion-title`}</ParagraphLargeStyle>
                <StarOpinionInnerDiv>
                  <H2Style fontWeight={500}>4.87</H2Style>
                  <StaticImage
                    className="stars-image"
                    src="../../../../images/for-companies/hs/stars.webp"
                    alt="image"
                    placeholder="none"
                    loading="lazy"
                  />
                </StarOpinionInnerDiv>
              </StarOpinionDiv>
            </StarOpinionTile>
            <OpinionsSlider
              slides={items}
              totalSlidesStateProp={items.length}
            />
          </FlexContainer>
        </div>
      </OpinionsContainer>
    </>
  );
};

export default OpinionsSection;
