import styled from 'styled-components';

export const CaseStudiesSliderContainer = styled.div`
  width: ${(props) => props.width || '100%'};
  max-width: 100%;
  box-sizing: border-box;
  position: relative;
  margin: auto;

  .slick-slide .case-study-shade {
    /* width: 387px !important; */
    opacity: 0.4;
    transition: opacity 0.5s ease-in-out, transform 0.3s ease-in-out;
  }

  .slick-current,
  .slick-current .case-study-shade {
    opacity: 1;
  }

  .slick-slider,
  .slick-slider .slick-slide div {
    /* padding: 0; */
    /* border-radius: 16px; */
  }

  .slick-slider .slick-slide {
    /* max-width: 280px; */
    @media only screen and (max-width: 600px) {
      /* max-width: 280px; */
    }
  }

  .slick-list {
    margin: auto;
  }

  .slick-list .slick-track > div,
  .slick-list .slick-track > div > div {
    height: 700px;
    box-sizing: border-box;
    display: grid;
    margin: auto;

    @media only screen and (max-width: 600px) {
      height: 800px;
    }
  }

  .slick-list .slick-track div {
    /* height: 320px; */
  }

  .slick-arrow {
    display: flex;
    width: 24px;
    height: 24px;
    padding: 8px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 5px;
    top: 95.5%;
    transform: none;
    z-index: 2;
    transition: all 0.2s ease-out;
  }

  .slick-prev {
    left: 45%;
  }

  .slick-next {
    right: 45%;
  }

  .slick-arrow:hover {
    background: var(--White, rgba(255, 255, 255, 0.9));
  }

  .slick-arrow:hover path {
    stroke: #231b32;
  }

  .slider-pagination {
    position: relative;
    color: var(--White, rgba(255, 255, 255, 0.9));
    font-family: Raleway;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-left: 70px;
    top: -12px;
  }

  @media only screen and (max-width: 1330px) {
    .slider-pagination {
      display: none !important;
    }

    .slick-prev {
      left: 40%;
    }

    .slick-next {
      right: 40%;
    }
  }
`;

export const CaseStudiesSlide = styled.div`
  transition: background-color 0.3s ease-in-out !important;
  padding: 0 !important;
  display: flex !important;
  height: 600px;
  box-sizing: border-box;
  flex-direction: column;
  align-items: flex-start;
  border-radius: 16px;
  overflow: hidden;
  background-color: var(--Outsource2-Alt-Sec, #231b32);
  cursor: pointer;

  .image {
    transition: all 0.3s ease-in-out;
    min-height: 160px;
    margin: 0 auto;
    padding: 0 !important;
    > div {
      padding: 0 !important;
    }
    img {
      /* width: fit-content; */
      /* width: 387px; */
    }
  }

  a {
    transition: all 0.3s ease-in-out !important;
    margin-top: auto;
    font-style: normal;
    line-height: 140%;
    text-decoration-line: underline;
    color: #6717be !important;
    padding: 0 16px 16px 16px !important;
  }

  &:hover {
    background-color: var(--Outsource2-Dark, #100722);
    .image {
      opacity: 0.7;
    }
    a {
      text-decoration-line: none;
    }
  }

  @media only screen and (max-width: 600px) {
    height: 700px;
  }
`;

export const BottomCaseStudy = styled.div`
  display: flex;
  padding: 16px 16px 0 16px !important;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;

  .tag-container {
    padding: 0 !important;

    @media only screen and (max-width: 400px) {
      flex-direction: column;
    }
  }
`;

export const TagContainer = styled.div`
  box-sizing: border-box;
  padding: 3px 12px !important;
  justify-content: center;
  align-items: center;
  border-radius: 24px;
  background: var(--Outsource2-Accent, #6717be);

  color: var(--Outsource2-Accent-50, #f2edf7);
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  letter-spacing: 0.6px;
`;
