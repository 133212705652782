import { useTranslation } from 'gatsby-plugin-react-i18next';
import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import Slider from 'react-slick';
import { TrustUsSliderContainer } from './styled.components';

const TrustUsSlider = ({ items }) => {
  const { t } = useTranslation();
  const settings = {
    dots: false,
    infinite: true,
    speed: 1000,
    slidesToShow: 7,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    arrows: false,
    responsive: [
      {
        breakpoint: 974,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 568,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1
        }
      }
    ]
  };

  const renderItems = items?.map((item, index) => {
    return (
      <div key={index} className="we-work-with-a-wrapper">
        <a href={item.link} target="_blank" rel="noreferrer nofollow">
          {item.img}
        </a>
      </div>
    );
  });

  return (
    <section>
      <TrustUsSliderContainer>
        <Slider {...settings}>{renderItems}</Slider>
      </TrustUsSliderContainer>
    </section>
  );
};

export default TrustUsSlider;
