import styled from 'styled-components';

export const GifSection = styled.div`
  /* z-index: 1; */
  video {
    /* height: 300px; */
    object-fit: cover;
    object-position: center;
    position: absolute;
    z-index: -0;
    /* bottom: 0; */
  }
`;
