import styled from 'styled-components';

export const TrustUsContainer = styled.div`
  .container {
    display: flex;
    padding: 40px 0px;
    flex-direction: column;
    align-items: center;
    /* gap: 16px; */
  }

  .image {
    width: 135px;
    box-sizing: border-box;
    flex-shrink: 0;
  }
`;
