import styled from 'styled-components';

export const NewsContainer = styled.div`
  .container {
    display: flex;
    padding: 80px 0px;
    flex-direction: column;
    align-items: center;
    gap: 16px;
  }

  .image img {
    height: 90px;
  }
`;
