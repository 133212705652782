import styled from 'styled-components';

export const HiContainer = styled.div`
  position: relative;
  overflow: hidden;
  .container {
    display: flex;
  }

  .circle-image {
    position: absolute !important;
  }

  .circle1 {
    top: 20px;
    left: -125px;
  }

  .circle2 {
    width: 200%;
    top: 120px;
  }

  @media only screen and (max-width: 1400px) {
    .circle1,
    .circle2 {
      display: none !important;
    }
  }

  @media only screen and (max-width: 1150px) {
    .container {
      flex-direction: column;
    }
  }
`;

export const HelloleftContainer = styled.div`
  display: flex;
  max-width: 680px;
  padding: 60px 0px;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;

  .purple-link {
    color: #b06ef7 !important;
    text-decoration: none;
  }
`;

export const HelloContactTile = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;

  .svg-image {
    width: 32px;
    height: 32px;
    padding: 4px;
    box-sizing: border-box;
    flex-shrink: 0;
    border-radius: 5px;
    background: var(--Outsource2-Alt-Sec, #231b32);
    box-shadow: 0px 0px 10px 0px rgba(5, 28, 38, 0.2);
  }

  a {
    text-decoration: none;
  }
`;

export const RelativeContainer = styled.div`
  display: inline-flex;
  position: relative;
  height: 100%;
  margin-left: auto;

  .image {
    bottom: -65px;
    img {
      width: 360px;
    }
  }
  @media only screen and (max-width: 1150px) {
    margin-right: auto;

    .image {
      bottom: -26px;
    }
  }
`;
