import styled, { createGlobalStyle } from 'styled-components';

import DunbarTall from '../../../static/assets/fonts/dunbar-tall/Dunbar-Tall-Book.ttf';
import DunbarTallExtraLight from '../../../static/assets/fonts/dunbar-tall/Dunbar-Tall-Extra-Light.ttf';

export const GlobalStyle = createGlobalStyle`
  @font-face {
    font-family: 'DunbarTall';
      src: url(${DunbarTallExtraLight}) format('truetype');
    font-weight: 200;
    font-style: normal;
  }

  @font-face {
    font-family: 'DunbarTall';
      src: url(${DunbarTall}) format('truetype');
    font-weight: 500;
    font-style: normal;
  }

  body {
    font-family: 'DunbarTall', sans-serif !important;
    background-color: #100722;
    scroll-behavior: initial !important;
  }

  a {
    color: #FFF !important;
    cursor: pointer;

    &:hover {
        color: #6717be !important;
    }
  }

  b {
    font-weight: 700;
  }

  .fixed-contact {
    position: fixed;
    bottom: 24px;
    right: 24px;
    z-index: 100 !important;
  }

  .ReactModal__Overlay {
    background-color: rgb(16, 7, 34, 0.7) !important;
  }
`;

export const H1Style = styled.h1`
  color: ${(props) => props.color || '#FFF'};
  font-size: 64px;
  font-style: normal;
  font-weight: ${(props) => props.fontWeight || 600};
  line-height: normal;
  letter-spacing: 3.2px;
  margin: 0;

  @media only screen and (max-width: 500px) {
    font-size: 40px;
  }
`;

export const H2Style = styled.h2`
  color: ${(props) => props.color || '#FFF'};
  font-size: 40px;
  font-style: normal;
  font-weight: ${(props) => props.fontWeight || 400};
  line-height: 150%;
  letter-spacing: 2px;
  margin: 0;
`;

export const H3Style = styled.h3`
  color: ${(props) => props.color || '#FFF'};
  font-size: 24px;
  font-style: normal;
  font-weight: ${(props) => props.fontWeight || 600};
  line-height: 150%;
  letter-spacing: 1.2px;
  opacity: ${(props) => props.opacity || 1};
  margin: 0;
`;

export const H4Style = styled.h3`
  color: ${(props) => props.color || '#9B52EA'};
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  letter-spacing: 1.8px;
  text-transform: ${(props) => props.textTransform || 'none'};
  margin: 0;
`;

export const ParagraphLargeStyle = styled.p`
  color: ${(props) => props.color || '#FFF'};
  font-size: 18px;
  font-style: normal;
  font-weight: 350;
  line-height: ${(props) => props.lineHeight || '170%'};
  letter-spacing: 0.9px;
  opacity: ${(props) => props.opacity || 1};
`;

export const ParagraphMediumStyle = styled.p`
  color: ${(props) => props.color || '#F2EDF7'};
  font-size: 16px;
  font-style: normal;
  font-weight: ${(props) => props.fontWeight || 600};
  line-height: ${(props) => props.lineHeight || '180%'};
  letter-spacing: 0.8px;
  opacity: ${(props) => props.opacity || 1};
`;

export const ParagraphSmallStyle = styled.p`
  color: ${(props) => props.color || '#FFF'};
  font-size: 14px;
  font-style: normal;
  font-weight: ${(props) => props.fontWeight || 300};
  line-height: ${(props) => props.lineHeight || '180%'};
  letter-spacing: 0.7px;
  opacity: ${(props) => props.opacity || 1};
`;

export const TransparentButton = styled.button`
  display: inline-flex;
  height: 55px;
  box-sizing: border-box;
  padding: 8px 32px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 5px;
  opacity: 1;
  background-color: rgba(0, 0, 0, 0);
  border: 2px solid var(--UI-Blue-Tint, #f2edf7);
  color: var(--UI-Blue-Tint, #f2edf7);
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  text-align: center;

  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%;
  letter-spacing: 0.8px;

  text-decoration: none;

  &:hover {
    background: var(--UI-Blue-Tint, #f5fafd);
    border: 2px solid var(--UI-Blue-Tint, #f5fafd);
    color: #6717be;
  }

  &.disable {
    pointer-events: none;
    background: #dddddd;
    border: 2px solid #dddddd;
    color: #ffffff !important;
  }
  z-index: 2;
`;

export const PurpleButton = styled(TransparentButton)`
  background-color: #6717be;
  border: 2px solid #6717be;
  color: var(--UI-Blue-Tint, #f2edf7);
`;

export const WhiteButton = styled(TransparentButton)`
  background: var(--UI-Blue-Tint, #f5fafd);
  border: 2px solid var(--UI-Blue-Tint, #f5fafd);
  color: #6717be !important;

  &:hover {
    background-color: rgba(0, 0, 0, 0);
    border: 2px solid var(--UI-Blue-Tint, #f2edf7);
    color: var(--UI-Blue-Tint, #f2edf7) !important;

    /* svg {
      path {
        stroke: #f2edf7;
      }
    } */
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  align-items: ${(props) => props.alignItems || 'flex-start'};
  gap: ${(props) => props.gap || '16px'};
  align-self: stretch;

  @media only screen and (max-width: 500px) {
    flex-direction: column;
    /* align-items: center; */
  }
`;

export const FlexContainer = styled.div`
  display: flex;
  align-items: ${(props) => props.alignItems || 'center'};
  gap: ${(props) => props.gap || '24px'};
  align-self: stretch;
`;

export const FlexColumnContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: ${(props) => props.alignItems || 'center'};
  gap: ${(props) => props.gap || '24px'};
  align-self: stretch;
`;

export const LogosContainer = styled.div`
  display: flex;
  /* height: 120px; */
  justify-content: center;
  align-items: center;
  gap: 40px;
  align-self: stretch;
  flex-wrap: wrap;

  @media only screen and (max-width: 1300px) {
  }
`;
