import React, { useState } from 'react';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import {
  ServicesContainer,
  ServicesTextContainer,
  TextTile,
  TextTileBottom,
  TextTileTop
} from './styled.components';
import {
  FlexColumnContainer,
  FlexContainer,
  H2Style,
  H3Style,
  H4Style,
  ParagraphSmallStyle
} from '../../styled.components';
import { Trans } from 'react-i18next';
import { StaticImage } from 'gatsby-plugin-image';

const ServicesSection = () => {
  const { t } = useTranslation();

  const [currentImage, setCurrentImage] = useState(
    <StaticImage
      className="image"
      src="../../../../images/for-companies/services/1.webp"
      alt="image"
      placeholder="none"
      loading="lazy"
    />
  );

  const items = [
    {
      title: t`for-companies.services-item-title1`,
      description: (
        <Trans
          i18nKey="for-companies.services-item-description1"
          components={{
            b: <b></b>,
            span: <span></span>,
            div: <div className="description-paragraph"></div>
          }}
        />
      ),
      image: (
        <StaticImage
          className="image"
          src="../../../../images/for-companies/services/1.webp"
          alt="image"
          placeholder="none"
          loading="lazy"
        />
      )
    },
    {
      title: t`for-companies.services-item-title2`,
      description: (
        <Trans
          i18nKey="for-companies.services-item-description2"
          components={{
            b: <b></b>,
            span: <span></span>,
            div: <div className="description-paragraph"></div>
          }}
        />
      ),
      image: (
        <StaticImage
          className="image"
          src="../../../../images/for-companies/services/2.webp"
          alt="image"
          placeholder="none"
          loading="lazy"
        />
      )
    },
    {
      title: t`for-companies.services-item-title3`,
      description: (
        <Trans
          i18nKey="for-companies.services-item-description3"
          components={{
            b: <b></b>,
            span: <span></span>,
            div: <div className="description-paragraph"></div>
          }}
        />
      ),
      image: (
        <StaticImage
          className="image"
          src="../../../../images/for-companies/services/3.webp"
          alt="image"
          placeholder="none"
          loading="lazy"
        />
      )
    },
    {
      title: t`for-companies.services-item-title4`,
      description: (
        <Trans
          i18nKey="for-companies.services-item-description4"
          components={{
            b: <b></b>,
            span: <span></span>,
            div: <div className="description-paragraph"></div>
          }}
        />
      ),
      image: (
        <StaticImage
          className="image"
          src="../../../../images/for-companies/services/4.webp"
          alt="image"
          placeholder="none"
          loading="lazy"
        />
      )
    },
    {
      title: t`for-companies.services-item-title5`,
      description: (
        <Trans
          i18nKey="for-companies.services-item-description5"
          components={{
            b: <b></b>,
            span: <span></span>,
            div: <div className="description-paragraph"></div>
          }}
        />
      ),
      image: (
        <StaticImage
          className="image"
          src="../../../../images/for-companies/services/5.webp"
          alt="image"
          placeholder="none"
          loading="lazy"
        />
      )
    },
    {
      title: t`for-companies.services-item-title6`,
      description: (
        <Trans
          i18nKey="for-companies.services-item-description6"
          components={{
            b: <b></b>,
            span: <span></span>,
            div: <div className="description-paragraph"></div>
          }}
        />
      ),
      image: (
        <StaticImage
          className="image"
          src="../../../../images/for-companies/services/6.webp"
          alt="image"
          placeholder="none"
          loading="lazy"
        />
      )
    }
  ];

  const [itemStates, setItemStates] = useState(() =>
    items.map((_, index) => index === 0)
  );

  const renderItems = items?.map((item, index) => {
    const isExpand = itemStates[index];

    const changeView = () => {
      setItemStates((prevStates) => {
        // If the clicked item is already expanded, collapse all.
        // if (prevStates[index]) {
        //   return prevStates.map(() => false);
        // }
        // Otherwise, expand only the clicked item.
        return prevStates.map((_, i) => i === index);
      });
      setCurrentImage(item.image);
    };

    return (
      <TextTile
        style={{
          cursor: isExpand ? 'auto' : 'pointer'
        }}
        key={index}>
        <TextTileTop onClick={changeView}>
          <H3Style
            style={{
              opacity: isExpand ? 1 : 0.6
            }}>
            {item.title}
          </H3Style>
          <svg
            className={isExpand ? 'rotate-svg' : ''}
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none">
            <path
              d="M18 9L12 15L6 9"
              stroke="#F2EDF7"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </TextTileTop>
        <TextTileBottom
          style={{
            maxHeight: isExpand ? '1500px' : '0',
            paddingBottom: isExpand ? '16px' : '0'
          }}>
          <ParagraphSmallStyle>{item.description}</ParagraphSmallStyle>
        </TextTileBottom>
      </TextTile>
    );
  });

  return (
    <>
      <ServicesContainer>
        <div className="container">
          <FlexColumnContainer gap="8px">
            <H4Style textTransform="uppercase">{t`for-companies.services-title`}</H4Style>
            <H2Style>
              <Trans
                i18nKey="for-companies.services-title2"
                components={{
                  b: <b></b>
                }}
              />
            </H2Style>
          </FlexColumnContainer>
          <FlexContainer
            alignItems="flex-start"
            gap="120px"
            className="services-data-container">
            <ServicesTextContainer>{renderItems}</ServicesTextContainer>
            {currentImage && <>{currentImage}</>}
          </FlexContainer>
        </div>
      </ServicesContainer>
    </>
  );
};

export default ServicesSection;
