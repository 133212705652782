import styled from 'styled-components';

export const CaseStudiesContainer = styled.div`
  .container {
  }
  display: flex;
  padding: 60px 0px;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;

  .cs-header {
    max-width: 800px;
    margin: auto;
    text-align: center;
    margin-bottom: 40px;
  }
`;

export const CsModalContainer = styled.div`
  display: flex;
  align-items: flex-start;
  height: 100%;
  width: 100%;
  box-sizing: border-box;
  position: relative;
  /* padding-bottom: 24px; */

  @media only screen and (max-width: 1000px) {
    flex-direction: column-reverse;
  }
`;

export const LeftCsModal = styled.div`
  width: 461px;
  flex-shrink: 0;
  margin: auto;

  /* .left-image {
    margin: auto;
  } */
  @media only screen and (max-width: 1000px) {
    margin-top: 24px;
    padding-bottom: 40px;
  }
  @media only screen and (max-width: 500px) {
    width: 300px;
  }
`;

export const RightCsModal = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex-grow: 1;
  box-sizing: border-box;
  max-height: 100%;
  overflow-y: auto;
  .close-svg {
    padding: 6px;
    position: absolute;
    top: 19px;
    right: 27px;
    cursor: pointer;
    z-index: 5;
  }
`;

export const RightTopCsModal = styled.div`
  display: flex;
  padding: 40px 24px 24px 24px;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
  align-self: stretch;
`;

export const H3Modal = styled.h3`
  color: var(--Outsource2-Accent-50, #f2edf7);
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%;
  letter-spacing: 1.6px;
  margin: 0;
`;

export const PModal = styled.p`
  color: var(--Outsource2-Accent-50, #f2edf7);
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  letter-spacing: 1px;
  margin: 0;
`;

export const TagsContainer = styled.div`
  display: flex;
  padding: 12px 24px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  background: var(--Outsource2-Alt-Sec, #231b32);
`;

export const RightBottomCsModal = styled.div`
  display: flex;
  padding: 24px 24px 24px 24px;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  align-self: stretch;

  .logo-image img {
    width: 200px;
  }
  /* box-sizing: border-box;
  max-height: 400px;
  overflow-y: auto; */

  /* @media only screen and (max-width: 1000px) {
    max-height: 300px;
  } */
`;

export const DescriptionContainer = styled.div`
  display: flex;
  flex-direction: column;

  .point-p {
    margin-left: 18px;
    margin-bottom: 6px;
  }
`;
