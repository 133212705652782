import styled from 'styled-components';

export const OpinionsContainer = styled.div`
  background: var(--Outsource2-Alt-Sec, #231b32);
  position: relative;
  overflow: hidden;
  .container {
    display: flex;
    padding: 80px 0px;
    flex-direction: column;
    align-items: flex-start;
    gap: 40px;

    h2 {
      max-width: 730px;
    }
  }

  .person-image {
    width: 58px;
    height: 58px;
    box-sizing: border-box;
    flex-shrink: 0;
    border-radius: 50%;
  }

  .image-circle1 {
    position: absolute !important;
    bottom: -80px;
    left: -200px;
  }

  .image-circle2 {
    position: absolute !important;
    top: 30px;
    right: 20px;
  }

  @media only screen and (max-width: 1300px) {
    .image-circle1,
    .image-circle2 {
      display: none !important;
    }
  }

  @media only screen and (max-width: 700px) {
    .flex-opinions {
      flex-direction: column;
    }
  }
`;

export const StarOpinionTile = styled.div`
  display: flex;
  width: 270px;
  box-sizing: border-box;
  flex-shrink: 0;
  padding: 16px;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
  border-radius: 16px;
  background: linear-gradient(
    180deg,
    rgba(103, 23, 190, 0) 0%,
    rgba(103, 23, 190, 0.4) 100%
  );
  box-shadow: 0px 0px 20px 0px rgba(15, 83, 115, 0.2);

  @media only screen and (max-width: 700px) {
    height: 400px;
    margin: auto;
  }
`;

export const StarOpinionDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
  flex: 1 0 0;
  align-self: stretch;
`;

export const StarOpinionInnerDiv = styled.div`
  display: flex;
  padding: 24px 0px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
  align-self: stretch;
`;
