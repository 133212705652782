import { useTranslation } from 'gatsby-plugin-react-i18next';
import React, { useState } from 'react';
import Slider from 'react-slick';
import { OpinionsSlide, OpinionsSliderContainer } from './styled.components';
import {
  FlexColumnContainer,
  FlexContainer,
  ParagraphMediumStyle
} from '../../../styled.components';

const OpinionsSlider = ({
  slides,
  totalSlidesStateProp = 8,
  isSliderAutoplay = true,
  speed = 1000,
  autoplaySpeed = 3000
}) => {
  const { t } = useTranslation();
  function SampleNextArrow(props) {
    const { className, onClick } = props;
    return (
      <svg
        className={className}
        onClick={onClick}
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none">
        <path
          d="M19 12H5"
          stroke="#FFFFFF"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M13 18L19 12"
          stroke="#FFFFFF"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M13 6L19 12"
          stroke="#FFFFFF"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    );
  }

  function SamplePrevArrow(props) {
    const { className, onClick } = props;
    return (
      <svg
        className={className}
        onClick={onClick}
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="#FFFFFF">
        <path
          d="M5 12H19"
          stroke="#FFFFFF"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M11 6L5 12"
          stroke="#FFFFFF"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M11 18L5 12"
          stroke="#FFFFFF"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    );
  }
  const [totalSlidesState, setTotalSlidesState] =
    useState(totalSlidesStateProp);
  const totalSlides = totalSlidesState;
  const slidesToShow = 3;

  const settings = {
    // dots: true,
    infinite: true,
    speed: speed,
    slidesToShow: slidesToShow,
    slidesToScroll: slidesToShow,
    autoplay: isSliderAutoplay,
    autoplaySpeed: autoplaySpeed,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    beforeChange: (currentSlide, nextSlide) => {
      setCurrentPage(Math.ceil(nextSlide / slidesToShow) + 1);
    },
    responsive: [
      {
        breakpoint: 1330,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2
        }
      },
      {
        breakpoint: 976,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 650,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };

  const totalPages = Math.ceil(totalSlides / slidesToShow);
  const [currentPage, setCurrentPage] = React.useState(1);
  return (
    <>
      <OpinionsSliderContainer>
        <Slider {...settings}>
          {slides.map((slide, index) => (
            <div>
              <OpinionsSlide key={index}>
                <ParagraphMediumStyle
                  fontWeight={300}
                  lineHeight="170%"
                  opacity={0.7}>
                  {slide.description}
                </ParagraphMediumStyle>
                <FlexContainer gap="11px" className="option-slide-person">
                  {slide.image}
                  <FlexColumnContainer alignItems="flex-start" gap="4px">
                    <ParagraphMediumStyle fontWeight="500" lineHeight="170%">
                      {slide.name}
                    </ParagraphMediumStyle>
                    <ParagraphMediumStyle
                      fontWeight="350"
                      lineHeight="150%"
                      color="#B570FF">
                      {slide.position}
                    </ParagraphMediumStyle>
                  </FlexColumnContainer>
                </FlexContainer>
              </OpinionsSlide>
            </div>
          ))}
        </Slider>
        <div className="slider-pagination">
          {currentPage}/{totalPages}
        </div>
      </OpinionsSliderContainer>
    </>
  );
};

export default OpinionsSlider;
