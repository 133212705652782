import React, { useContext, useEffect, useState } from 'react';
import { I18nextContext, useTranslation } from 'gatsby-plugin-react-i18next';
import {
  CaseStudiesContainer,
  CsModalContainer,
  DescriptionContainer,
  H3Modal,
  LeftCsModal,
  PModal,
  RightBottomCsModal,
  RightCsModal,
  RightTopCsModal,
  TagsContainer
} from './styled.components';
import {
  FlexColumnContainer,
  FlexContainer,
  H2Style,
  H4Style,
  ParagraphLargeStyle,
  ParagraphMediumStyle,
  ParagraphSmallStyle
} from '../../styled.components';
import { graphql, useStaticQuery } from 'gatsby';
import getCurrentTranslations from '../../../../components/contentful-translator';
import CaseStudiesSlider from './components';
import Modal from 'react-modal';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { TagContainer } from './components/styled.components';

const CaseStudiesSection = () => {
  const { t } = useTranslation();
  const { language } = useContext(I18nextContext);
  const data = useStaticQuery(graphql`
    query {
      allContentfulCaseStudies(filter: { page: { in: ["Dla firm"] } }) {
        edges {
          node {
            createdAt
            node_locale
            title
            page
            headerImage {
              gatsbyImageData
            }
            description {
              description
            }
            tags
            modalTitle1
            modalTitle2
            modalAppName
            modalPointsName
            modalPoints
            link
            linkLogo {
              gatsbyImageData
            }
          }
        }
      }
    }
  `);
  const [caseStudies, setCaseStudies] = useState();

  useEffect(() => {
    const getCaseStudies = () => {
      let getCaseStudies = getCurrentTranslations(
        data.allContentfulCaseStudies.edges,
        language
      );
      getCaseStudies = getCaseStudies.filter(
        (item) => item.node.title !== 'Placeholder (nie usuwać)'
      );
      setCaseStudies(getCaseStudies);
      console.log(getCaseStudies);
    };
    getCaseStudies();
  }, [data.allContentfulCaseStudies, language]);

  // Modal
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currnetData, setCurrnetData] = useState();

  const [boxStyles, setBoxStyles] = useState({
    overlay: {
      zIndex: 103
    },
    content: {
      margin: 'auto',
      padding: '0',
      inset: '0',
      // width: '80%',
      maxHeight: '650px',
      maxWidth: '1150px',
      borderRadius: '16px',
      background: '#100722',
      border: 'none',
      overflow: 'hidden'
    }
  });

  useEffect(() => {
    const mediaQuery = window.matchMedia('(max-width: 1000px)');

    const updateBoxStyles = (e) => {
      if (e.matches) {
        setBoxStyles((prevStyles) => ({
          ...prevStyles,
          content: {
            ...prevStyles.content,
            maxWidth: '100%',
            maxHeight: '100%',
            borderRadius: '0'
          }
        }));
      } else {
        setBoxStyles((prevStyles) => ({
          ...prevStyles,
          content: {
            ...prevStyles.content,
            maxWidth: '1150px',
            maxHeight: '650px',
            borderRadius: '16px'
          }
        }));
      }
    };

    updateBoxStyles(mediaQuery);

    mediaQuery.addEventListener('change', updateBoxStyles);

    return () => {
      mediaQuery.removeEventListener('change', updateBoxStyles);
    };
  }, []);

  const openModal = (item) => {
    console.log(item);
    setCurrnetData(item);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <Modal
        isOpen={isModalOpen}
        style={boxStyles}
        onRequestClose={closeModal}
        shouldCloseOnOverlayClick={true}
        ariaHideApp={false}>
        {currnetData && (
          <CsModalContainer>
            <LeftCsModal>
              <GatsbyImage
                alt={currnetData.node.title}
                placeholder={currnetData.node.title}
                className="left-image"
                image={getImage(currnetData.node.headerImage.gatsbyImageData)}
              />
            </LeftCsModal>
            <RightCsModal>
              <svg
                onClick={closeModal}
                className="close-svg"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none">
                <path
                  d="M18 6L6 18M6 6L18 18"
                  stroke="#F2EDF7"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
              <RightTopCsModal>
                <H3Modal>
                  {currnetData.node.modalTitle1 && currnetData.node.modalTitle1}
                </H3Modal>
                <PModal>
                  {currnetData.node.modalTitle2 && currnetData.node.modalTitle2}
                </PModal>
              </RightTopCsModal>
              <TagsContainer>
                <ParagraphSmallStyle fontWeight={400} lineHeight="150%">
                  {currnetData.node.modalAppName &&
                    currnetData.node.modalAppName}
                </ParagraphSmallStyle>
                <FlexContainer
                  alignItems="flex-start"
                  gap="4px"
                  className="tag-container">
                  {currnetData.node.tags &&
                    currnetData.node.tags.map((tag, index) => (
                      <TagContainer key={index}>{tag}</TagContainer>
                    ))}
                </FlexContainer>
              </TagsContainer>
              <RightBottomCsModal>
                <DescriptionContainer>
                  <ParagraphMediumStyle fontWeight={350} opacity={0.7}>
                    {currnetData.node.description.description}
                  </ParagraphMediumStyle>
                  <ParagraphMediumStyle fontWeight={350} opacity={0.7}>
                    {currnetData.node.modalPointsName &&
                      currnetData.node.modalPointsName}
                  </ParagraphMediumStyle>
                  {currnetData.node.modalPoints &&
                    currnetData.node.modalPoints.map((point, index) => (
                      <ParagraphMediumStyle
                        className="point-p"
                        fontWeight={350}
                        opacity={0.7}
                        key={index}>
                        • {point}
                      </ParagraphMediumStyle>
                    ))}
                </DescriptionContainer>
                <FlexColumnContainer gap="8px" alignItems="flex-start">
                  <ParagraphMediumStyle fontWeight={350} opacity={0.7}>
                    {t`for-companies.cs-realization`}
                  </ParagraphMediumStyle>
                  {currnetData.node.link && currnetData.node.linkLogo && (
                    <a
                      href={currnetData.node.link}
                      target="_blank"
                      rel="noreferrer">
                      <GatsbyImage
                        alt={currnetData.node.title}
                        placeholder={currnetData.node.title}
                        className="logo-image"
                        image={getImage(
                          currnetData.node.linkLogo.gatsbyImageData
                        )}
                      />
                    </a>
                  )}
                </FlexColumnContainer>
              </RightBottomCsModal>
            </RightCsModal>
          </CsModalContainer>
        )}
      </Modal>
      <CaseStudiesContainer>
        {/* <div className="container"> */}
        <FlexColumnContainer gap="8px" className="cs-header">
          <H4Style textTransform="uppercase">{t`for-companies.cs-title`}</H4Style>
          <H2Style>{t`for-companies.cs-title2`}</H2Style>
          <ParagraphLargeStyle
            opacity={0.7}>{t`for-companies.cs-title3`}</ParagraphLargeStyle>
        </FlexColumnContainer>
        {caseStudies && caseStudies.length > 0 ? (
          <CaseStudiesSlider
            openModal={openModal}
            slides={caseStudies}
            totalSlidesStateProp={caseStudies.length}
          />
        ) : (
          <H2Style>{t`for-companies.cs-soon`}</H2Style>
        )}
        {/* </div> */}
      </CaseStudiesContainer>
    </>
  );
};

export default CaseStudiesSection;
