import styled from 'styled-components';

export const WhyWeContainer = styled.div`
  background: var(--Outsource2-Alt-Sec, #231b32);
  position: relative;
  overflow: hidden;
  .container {
    display: flex;
    padding: 80px 0px;
    flex-direction: column;
    align-items: flex-start;
    gap: 40px;
  }

  .ww-header {
    max-width: 684px;
  }

  .circle-image {
    position: absolute !important;
  }

  .circle1 {
    bottom: 46%;
    left: -110px;
  }

  .circle2 {
    top: 10px;
    right: -60px;
  }

  .circle3 {
    bottom: 20px;
    right: 340px;
  }

  @media only screen and (max-width: 1300px) {
    .circle1,
    .circle2,
    .circle3 {
      display: none !important;
    }
  }
`;

export const WhyWeTiles = styled.div`
  display: flex;
  align-items: center;
  align-content: center;
  gap: 24px;
  align-self: stretch;
  flex-wrap: wrap;

  @media only screen and (max-width: 1300px) {
    flex-direction: column;
  }
`;

export const WhyWeTile = styled.div`
  display: flex;
  padding: 16px;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
  border-radius: 16px;
  background: var(--Outsource2-Dark, #100722);
  box-shadow: 0px 0px 20px 0px rgba(15, 83, 115, 0.2);
  width: 377px;
  box-sizing: border-box;
  z-index: 2;

  svg {
    display: flex;
    padding: 8px;
    align-items: center;
    gap: 10px;
    border-radius: 8px;
    background: linear-gradient(180deg, #6717be 0%, rgba(103, 23, 190, 0) 100%);
    flex-shrink: 0;
  }

  @media only screen and (max-width: 1300px) {
    margin: auto;
  }

  @media only screen and (max-width: 500px) {
    width: 300px;
  }
`;
