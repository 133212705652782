import React, { useState } from 'react';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import {
  AdventagesContainer,
  ImageContainer,
  OutsourceTile,
  SwitchButton,
  SwitchingButtonsContainer,
  SwitchingDataContainer
} from './styled.components';
import {
  FlexColumnContainer,
  FlexContainer,
  H2Style,
  H3Style,
  H4Style,
  ParagraphMediumStyle,
  ParagraphSmallStyle
} from '../../styled.components';
import { Trans } from 'react-i18next';
import { StaticImage } from 'gatsby-plugin-image';

const AdventagesSection = () => {
  const { t } = useTranslation();
  const [currentView, setCurrentView] = useState('withOutsource2');

  const withOutsourceData = [
    {
      image: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none">
          <path
            d="M3 3V21H21M20 18V21M16 16V21M12 13V21M8 16V21M3 11C9 11 8 6 12 6C16 6 15 11 21 11"
            stroke="#F2EDF7"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      ),
      title: t`for-companies.a-with-outsource2-title1`,
      description: t`for-companies.a-with-outsource2-description1`
    },
    {
      image: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none">
          <path
            d="M21 21L15 15M7 10L9 12L13 8M3 10C3 10.9193 3.18106 11.8295 3.53284 12.6788C3.88463 13.5281 4.40024 14.2997 5.05025 14.9497C5.70026 15.5998 6.47194 16.1154 7.32122 16.4672C8.1705 16.8189 9.08075 17 10 17C10.9193 17 11.8295 16.8189 12.6788 16.4672C13.5281 16.1154 14.2997 15.5998 14.9497 14.9497C15.5998 14.2997 16.1154 13.5281 16.4672 12.6788C16.8189 11.8295 17 10.9193 17 10C17 9.08075 16.8189 8.1705 16.4672 7.32122C16.1154 6.47194 15.5998 5.70026 14.9497 5.05025C14.2997 4.40024 13.5281 3.88463 12.6788 3.53284C11.8295 3.18106 10.9193 3 10 3C9.08075 3 8.1705 3.18106 7.32122 3.53284C6.47194 3.88463 5.70026 4.40024 5.05025 5.05025C4.40024 5.70026 3.88463 6.47194 3.53284 7.32122C3.18106 8.1705 3 9.08075 3 10Z"
            stroke="#F2EDF7"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      ),
      title: t`for-companies.a-with-outsource2-title2`,
      description: t`for-companies.a-with-outsource2-description2`
    },
    {
      image: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none">
          <path
            d="M9 14L15 8M10 8.5C10 8.77614 9.77614 9 9.5 9C9.22386 9 9 8.77614 9 8.5C9 8.22386 9.22386 8 9.5 8C9.77614 8 10 8.22386 10 8.5ZM15 13.5C15 13.7761 14.7761 14 14.5 14C14.2239 14 14 13.7761 14 13.5C14 13.2239 14.2239 13 14.5 13C14.7761 13 15 13.2239 15 13.5ZM5 21V5C5 4.46957 5.21071 3.96086 5.58579 3.58579C5.96086 3.21071 6.46957 3 7 3H17C17.5304 3 18.0391 3.21071 18.4142 3.58579C18.7893 3.96086 19 4.46957 19 5V21L16 19L14 21L12 19L10 21L8 19L5 21Z"
            stroke="#F2EDF7"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      ),
      title: t`for-companies.a-with-outsource2-title3`,
      description: t`for-companies.a-with-outsource2-description3`
    },
    {
      image: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none">
          <path
            d="M9 14L15 8M10 8.5C10 8.77614 9.77614 9 9.5 9C9.22386 9 9 8.77614 9 8.5C9 8.22386 9.22386 8 9.5 8C9.77614 8 10 8.22386 10 8.5ZM15 13.5C15 13.7761 14.7761 14 14.5 14C14.2239 14 14 13.7761 14 13.5C14 13.2239 14.2239 13 14.5 13C14.7761 13 15 13.2239 15 13.5ZM5 21V5C5 4.46957 5.21071 3.96086 5.58579 3.58579C5.96086 3.21071 6.46957 3 7 3H17C17.5304 3 18.0391 3.21071 18.4142 3.58579C18.7893 3.96086 19 4.46957 19 5V21L16 19L14 21L12 19L10 21L8 19L5 21Z"
            stroke="#F2EDF7"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      ),
      title: t`for-companies.a-with-outsource2-title4`,
      description: t`for-companies.a-with-outsource2-description4`
    },
    {
      image: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none">
          <path
            d="M12 19H6C5.20435 19 4.44129 18.6839 3.87868 18.1213C3.31607 17.5587 3 16.7956 3 16V8C3 7.20435 3.31607 6.44129 3.87868 5.87868C4.44129 5.31607 5.20435 5 6 5H18C18.7956 5 19.5587 5.31607 20.1213 5.87868C20.6839 6.44129 21 7.20435 21 8V12.5M3 10H21M7 15H7.01M11 15H13M16 19H22M16 19L19 16M16 19L19 22"
            stroke="#F2EDF7"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      ),
      title: t`for-companies.a-with-outsource2-title5`,
      description: t`for-companies.a-with-outsource2-description5`
    },
    {
      image: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none">
          <path
            d="M8 16L6.553 16.724C6.38692 16.807 6.24722 16.9346 6.14955 17.0925C6.05188 17.2504 6.0001 17.4323 6 17.618V20H18V17.618C17.9999 17.4323 17.9481 17.2504 17.8504 17.0925C17.7528 16.9346 17.6131 16.807 17.447 16.724L16 16H8Z"
            stroke="#F2EDF7"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M9 3.00002L10 6.00002L6.509 8.14802C6.32341 8.26206 6.18013 8.43358 6.10095 8.63651C6.02177 8.83944 6.01101 9.06267 6.07031 9.27227C6.12962 9.48187 6.25574 9.66637 6.4295 9.79773C6.60327 9.92909 6.81517 10.0001 7.033 10H10L7.927 16H15.888L16 11C16 8.00002 14.91 5.01702 12 4.00002C10.06 3.32202 9.06 2.98902 9 3.00002Z"
            stroke="#F2EDF7"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      ),
      title: t`for-companies.a-with-outsource2-title6`,
      description: t`for-companies.a-with-outsource2-description6`
    },
    {
      image: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none">
          <path
            d="M9 5H7C6.46957 5 5.96086 5.21071 5.58579 5.58579C5.21071 5.96086 5 6.46957 5 7V19C5 19.5304 5.21071 20.0391 5.58579 20.4142C5.96086 20.7893 6.46957 21 7 21H17C17.5304 21 18.0391 20.7893 18.4142 20.4142C18.7893 20.0391 19 19.5304 19 19V7C19 6.46957 18.7893 5.96086 18.4142 5.58579C18.0391 5.21071 17.5304 5 17 5H15M9 5C9 4.46957 9.21071 3.96086 9.58579 3.58579C9.96086 3.21071 10.4696 3 11 3H13C13.5304 3 14.0391 3.21071 14.4142 3.58579C14.7893 3.96086 15 4.46957 15 5M9 5C9 5.53043 9.21071 6.03914 9.58579 6.41421C9.96086 6.78929 10.4696 7 11 7H13C13.5304 7 14.0391 6.78929 14.4142 6.41421C14.7893 6.03914 15 5.53043 15 5M11.993 16.75L14.74 13.935C15.0798 13.5811 15.2695 13.1096 15.2695 12.619C15.2695 12.1284 15.0798 11.6568 14.74 11.303C14.5744 11.1306 14.3756 10.9934 14.1556 10.8997C13.9357 10.806 13.6991 10.7577 13.46 10.7577C13.2209 10.7577 12.9843 10.806 12.7643 10.8997C12.5444 10.9934 12.3456 11.1306 12.18 11.303L11.997 11.491L11.814 11.302C11.6484 11.1296 11.4496 10.9924 11.2296 10.8987C11.0097 10.805 10.7731 10.7567 10.534 10.7567C10.2949 10.7567 10.0583 10.805 9.83834 10.8987C9.61839 10.9924 9.41963 11.1296 9.25399 11.302C8.91397 11.6557 8.72406 12.1273 8.72406 12.618C8.72406 13.1086 8.91397 13.5803 9.25399 13.934L11.992 16.759L11.993 16.75Z"
            stroke="#F2EDF7"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      ),
      title: t`for-companies.a-with-outsource2-title7`,
      description: t`for-companies.a-with-outsource2-description7`
    },
    {
      image: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none">
          <path
            d="M15 15L18.35 18.35M8.99999 15L5.64999 18.35M5.64999 5.65002L8.99999 9.00002M18.35 5.65002L15 9.00002M8 12C8 13.0609 8.42143 14.0783 9.17157 14.8284C9.92172 15.5786 10.9391 16 12 16C13.0609 16 14.0783 15.5786 14.8284 14.8284C15.5786 14.0783 16 13.0609 16 12C16 10.9391 15.5786 9.92172 14.8284 9.17157C14.0783 8.42143 13.0609 8 12 8C10.9391 8 9.92172 8.42143 9.17157 9.17157C8.42143 9.92172 8 10.9391 8 12ZM3 12C3 13.1819 3.23279 14.3522 3.68508 15.4442C4.13738 16.5361 4.80031 17.5282 5.63604 18.364C6.47177 19.1997 7.46392 19.8626 8.55585 20.3149C9.64778 20.7672 10.8181 21 12 21C13.1819 21 14.3522 20.7672 15.4442 20.3149C16.5361 19.8626 17.5282 19.1997 18.364 18.364C19.1997 17.5282 19.8626 16.5361 20.3149 15.4442C20.7672 14.3522 21 13.1819 21 12C21 10.8181 20.7672 9.64778 20.3149 8.55585C19.8626 7.46392 19.1997 6.47177 18.364 5.63604C17.5282 4.80031 16.5361 4.13738 15.4442 3.68508C14.3522 3.23279 13.1819 3 12 3C10.8181 3 9.64778 3.23279 8.55585 3.68508C7.46392 4.13738 6.47177 4.80031 5.63604 5.63604C4.80031 6.47177 4.13738 7.46392 3.68508 8.55585C3.23279 9.64778 3 10.8181 3 12Z"
            stroke="#F2EDF7"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      ),
      title: t`for-companies.a-with-outsource2-title8`,
      description: t`for-companies.a-with-outsource2-description8`
    }
  ];

  const renderItems = (items) => {
    return items?.map((item, index) => (
      <OutsourceTile key={index}>
        <FlexContainer alignItems="flex-start" gap="8px">
          {item.image}
          <ParagraphMediumStyle lineHeight="150%">
            {item.title}
          </ParagraphMediumStyle>
        </FlexContainer>
        <ParagraphSmallStyle lineHeight="170%" opacity={0.7}>
          {item.description}
        </ParagraphSmallStyle>
      </OutsourceTile>
    ));
  };

  return (
    <>
      <AdventagesContainer>
        {currentView === 'withoutOutsource2' && (
          <StaticImage
            className="image-circle3"
            src="../../../../images/for-companies/adventages/circle3.webp"
            alt="image"
            placeholder="none"
            loading="lazy"
          />
        )}
        <div className="container">
          <FlexColumnContainer gap="24px">
            <FlexColumnContainer gap="8px">
              <H4Style textTransform="uppercase">{t`for-companies.a-title`}</H4Style>
              <H2Style>
                <Trans
                  i18nKey="for-companies.a-title2"
                  components={{
                    b: <b></b>
                  }}
                />
              </H2Style>
              <H3Style
                opacity={0.7}
                fontWeight={400}>{t`for-companies.a-title3`}</H3Style>
            </FlexColumnContainer>
            <SwitchingButtonsContainer>
              <SwitchButton
                onClick={() => setCurrentView('withOutsource2')}
                className={
                  currentView === 'withOutsource2' ? 'checked' : ''
                }>{t`for-companies.a-with-outsource2`}</SwitchButton>
              <SwitchButton
                onClick={() => setCurrentView('withoutOutsource2')}
                className={
                  currentView === 'withoutOutsource2' ? 'checked' : ''
                }>{t`for-companies.a-without-outsource2`}</SwitchButton>
            </SwitchingButtonsContainer>
          </FlexColumnContainer>
          {currentView === 'withOutsource2' && (
            <SwitchingDataContainer>
              <FlexColumnContainer>
                {renderItems(withOutsourceData.slice(0, 4))}
              </FlexColumnContainer>
              <ImageContainer>
                <StaticImage
                  className="image"
                  src="../../../../images/for-companies/adventages/1.webp"
                  alt="image"
                  placeholder="none"
                  loading="lazy"
                />
              </ImageContainer>
              <FlexColumnContainer>
                {renderItems(withOutsourceData.slice(4))}
              </FlexColumnContainer>
            </SwitchingDataContainer>
          )}
          {currentView === 'withoutOutsource2' && (
            <FlexContainer
              gap="0px"
              className="withoutOutsource2-flex-container">
              <StaticImage
                className="image-circle1"
                src="../../../../images/for-companies/adventages/circle1.webp"
                alt="image"
                placeholder="none"
                loading="lazy"
              />
              <StaticImage
                className="image-circle2"
                src="../../../../images/for-companies/adventages/circle2.webp"
                alt="image"
                placeholder="none"
                loading="lazy"
              />
              <StaticImage
                className="image"
                src="../../../../images/for-companies/adventages/2.webp"
                alt="image"
                placeholder="none"
                loading="lazy"
              />
              <StaticImage
                className="image"
                src="../../../../images/for-companies/adventages/3.webp"
                alt="image"
                placeholder="none"
                loading="lazy"
              />
            </FlexContainer>
          )}
        </div>
      </AdventagesContainer>
    </>
  );
};

export default AdventagesSection;
