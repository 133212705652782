import styled from 'styled-components';

export const HeroContainer = styled.div`
  margin-top: var(--menu-height);
  position: relative;
  .container {
    display: flex;
    width: 100%;

    @media only screen and (max-width: 1325px) {
      flex-direction: column;
      width: fit-content;
    }
  }
`;

export const LeftHeroContainer = styled.div`
  display: flex;
  padding: 60px 0px;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  flex-shrink: 0;
  gap: 60px;
  margin-right: auto;

  @media only screen and (max-width: 500px) {
    margin: 0;
  }
`;

export const RelativeContainer = styled.div`
  overflow: hidden;
  display: inline-flex;
  margin-top: auto;
  position: absolute;
  bottom: 0;
  right: 0;
  width: 800px;
  box-sizing: border-box;

  .kacper-image {
    z-index: 5;
  }

  .circle-image {
    position: absolute !important;
  }

  .circle-gif {
    video {
      width: 100%;
      bottom: 160px;
    }
  }

  @media only screen and (max-width: 1500px) {
    width: 600px;

    /* .circle-gif {
      video {
        width: 600px;
      }
    } */
  }

  @media only screen and (max-width: 1325px) {
    position: relative;
    margin: auto;
    width: 100%;

    /* .circle-gif {
      video {
        width: 100%;
      }
    } */
  }
`;

export const OpinionsContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;
