import React from 'react';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { TrustUsContainer } from './styled.components';
import { H3Style } from '../../styled.components';
import { StaticImage } from 'gatsby-plugin-image';
import TrustUsSlider from './components';

const TrustUsSection = () => {
  const { t } = useTranslation();

  const items = [
    {
      link: 'https://www.gpw.pl/',
      img: (
        <StaticImage
          className="image"
          src="../../../../images/for-companies/tu/1.webp"
          alt="image"
          placeholder="none"
          loading="lazy"
        />
      )
    },
    {
      link: 'https://www.astrazeneca.pl/',
      img: (
        <StaticImage
          className="image"
          src="../../../../images/for-companies/tu/2.webp"
          alt="image"
          placeholder="none"
          loading="lazy"
        />
      )
    },
    {
      link: 'https://inpost.pl/',
      img: (
        <StaticImage
          className="image"
          src="../../../../images/for-companies/tu/3.webp"
          alt="image"
          placeholder="none"
          loading="lazy"
        />
      )
    },
    {
      link: 'https://www.pw.edu.pl/',
      img: (
        <StaticImage
          className="image"
          src="../../../../images/for-companies/tu/4.webp"
          alt="image"
          placeholder="none"
          loading="lazy"
        />
      )
    },
    {
      link: 'https://www.uw.edu.pl/',
      img: (
        <StaticImage
          className="image"
          src="../../../../images/for-companies/tu/5.webp"
          alt="image"
          placeholder="none"
          loading="lazy"
        />
      )
    },
    {
      link: '',
      img: (
        <StaticImage
          className="image"
          src="../../../../images/for-companies/tu/6.webp"
          alt="image"
          placeholder="none"
          loading="lazy"
        />
      )
    },
    {
      link: '',
      img: (
        <StaticImage
          className="image"
          src="../../../../images/for-companies/tu/7.webp"
          alt="image"
          placeholder="none"
          loading="lazy"
        />
      )
    },
    {
      link: 'https://www.sgh.waw.pl/',
      img: (
        <StaticImage
          className="image"
          src="../../../../images/for-companies/tu/8.webp"
          alt="image"
          placeholder="none"
          loading="lazy"
        />
      )
    },
    {
      link: '',
      img: (
        <StaticImage
          className="image"
          src="../../../../images/for-companies/tu/9.webp"
          alt="image"
          placeholder="none"
          loading="lazy"
        />
      )
    },
    {
      link: '',
      img: (
        <StaticImage
          className="image"
          src="../../../../images/for-companies/tu/10.webp"
          alt="image"
          placeholder="none"
          loading="lazy"
        />
      )
    },
    {
      link: '',
      img: (
        <StaticImage
          className="image"
          src="../../../../images/for-companies/tu/11.webp"
          alt="image"
          placeholder="none"
          loading="lazy"
        />
      )
    },
    {
      link: '',
      img: (
        <StaticImage
          className="image"
          src="../../../../images/for-companies/tu/12.webp"
          alt="image"
          placeholder="none"
          loading="lazy"
        />
      )
    },
    {
      link: '',
      img: (
        <StaticImage
          className="image"
          src="../../../../images/for-companies/tu/13.webp"
          alt="image"
          placeholder="none"
          loading="lazy"
        />
      )
    },
    {
      link: '',
      img: (
        <StaticImage
          className="image"
          src="../../../../images/for-companies/tu/14.webp"
          alt="image"
          placeholder="none"
          loading="lazy"
        />
      )
    },
    {
      link: '',
      img: (
        <StaticImage
          className="image"
          src="../../../../images/for-companies/tu/15.webp"
          alt="image"
          placeholder="none"
          loading="lazy"
        />
      )
    },
    {
      link: '',
      img: (
        <StaticImage
          className="image"
          src="../../../../images/for-companies/tu/16.webp"
          alt="image"
          placeholder="none"
          loading="lazy"
        />
      )
    },
    {
      link: '',
      img: (
        <StaticImage
          className="image"
          src="../../../../images/for-companies/tu/17.webp"
          alt="image"
          placeholder="none"
          loading="lazy"
        />
      )
    },
    {
      link: '',
      img: (
        <StaticImage
          className="image"
          src="../../../../images/for-companies/tu/18.webp"
          alt="image"
          placeholder="none"
          loading="lazy"
        />
      )
    },
    {
      link: '',
      img: (
        <StaticImage
          className="image"
          src="../../../../images/for-companies/tu/19.webp"
          alt="image"
          placeholder="none"
          loading="lazy"
        />
      )
    },
    {
      link: '',
      img: (
        <StaticImage
          className="image"
          src="../../../../images/for-companies/tu/20.webp"
          alt="image"
          placeholder="none"
          loading="lazy"
        />
      )
    }
  ];

  return (
    <>
      <TrustUsContainer>
        <div className="container">
          <H3Style fontWeight={400}>{t`for-companies.tu-title`}</H3Style>
          {items && <TrustUsSlider items={items} />}
        </div>
      </TrustUsContainer>
    </>
  );
};

export default TrustUsSection;
