import styled from 'styled-components';

export const ServicesContainer = styled.div`
  .container {
    display: flex;
    padding: 60px 0px;
    flex-direction: column;
    align-items: flex-start;
    gap: 60px;
  }

  @media only screen and (max-width: 1330px) {
    .services-data-container {
      flex-direction: column;
      gap: 24px;
    }
  }
`;

export const ServicesTextContainer = styled.div`
  display: flex;
  max-width: 488px;
  flex-shrink: 0;
  flex-direction: column;
  align-items: flex-start;

  @media only screen and (max-width: 1330px) {
    margin: auto;
  }
`;

export const TextTile = styled.div`
  display: flex;
  /* padding: 24px 0px; */
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
  border-bottom: 1px solid rgba(242, 237, 247, 0.3);
  /* opacity: 0.6; */
  cursor: pointer;
`;

export const TextTileTop = styled.div`
  padding-top: 24px;
  display: flex;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;

  svg {
    padding: 8px;
    transition: transform 0.3s ease-in-out;
    flex-shrink: 0;
    opacity: 0.6;
    margin-left: auto;
  }
  .rotate-svg {
    transform: rotate(180deg);
    transform-origin: center;
    opacity: 1;
  }

  /* h3 {
    opacity: 0.6 !important;
  } */
`;

export const TextTileBottom = styled.div`
  padding-bottom: 16px;
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.2s ease-in-out, padding 0.2s ease-in-out;

  b {
    opacity: 1;
  }

  span {
    opacity: 0.6;
  }

  .description-paragraph {
    margin-bottom: 24px;
  }
`;
