import { useTranslation } from 'react-i18next';
import Layout from '../../components/layout/layout';
import Seo from '../../components/seo';
import React from 'react';
import HeroSection from './components/hero-section';
import { GlobalStyle, WhiteButton } from './styled.components';
import TrustUsSection from './components/trust-us';
import AdventagesSection from './components/adventages';
import OpinionsSection from './components/opinions';
import NewsSection from './components/news';
import ServicesSection from './components/services';
import HaveDoubtsSection from './components/have-doubts';
import CaseStudiesSection from './components/case-studies';
import WhyWeSection from './components/why-we';
import HiSection from './components/hi-section';

const ForCompanies = () => {
  const { t } = useTranslation();

  return (
    <>
      <Layout withHeader={false} darkTheme={true}>
        <Seo
          title={t`for-companies.seo-title`}
          description={t`for-companies.seo-description`}
        />
        <GlobalStyle />
        <HeroSection />
        <TrustUsSection />
        <AdventagesSection />
        <div id="opinions">
          <OpinionsSection />
        </div>
        <NewsSection />
        <div id="services">
          <ServicesSection />
        </div>
        <HaveDoubtsSection />
        <CaseStudiesSection />
        <WhyWeSection />
        <div id="contact">
          <HiSection />
        </div>
        <WhiteButton as="a" href="#contact" className="fixed-contact">
          {t`for-companies.contact`}{' '}
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="25"
            viewBox="0 0 24 25"
            fill="none">
            <path
              d="M12 19.5L12 5.5"
              stroke="#6717BE"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M6 13.5L12 19.5"
              stroke="#6717BE"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M18 13.5L12 19.5"
              stroke="#6717BE"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </WhiteButton>
      </Layout>
    </>
  );
};

export default ForCompanies;
