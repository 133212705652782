import React from 'react';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import {
  HelloContactTile,
  HelloleftContainer,
  HiContainer,
  RelativeContainer
} from './styled.components';
import {
  FlexColumnContainer,
  H2Style,
  ParagraphLargeStyle,
  PurpleButton
} from '../../styled.components';
import { Trans } from 'react-i18next';
import { StaticImage } from 'gatsby-plugin-image';

const HiSection = () => {
  const { t } = useTranslation();

  return (
    <>
      <HiContainer>
        <StaticImage
          className="circle-image circle1"
          src="../../../../images/for-companies/hi/circle1.webp"
          alt="image"
          placeholder="none"
          loading="lazy"
        />
        <div className="container">
          <HelloleftContainer>
            <H2Style fontWeight={600}>{t`for-companies.hello-title`}</H2Style>
            <ParagraphLargeStyle>
              <Trans
                i18nKey="for-companies.hello-description"
                components={{
                  b: <b></b>
                }}
              />
            </ParagraphLargeStyle>
            <FlexColumnContainer gap="12px" alignItems="flex-start">
              <HelloContactTile>
                <svg
                  className="svg-image"
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none">
                  <path
                    d="M3 7C3 6.46957 3.21071 5.96086 3.58579 5.58579C3.96086 5.21071 4.46957 5 5 5H19C19.5304 5 20.0391 5.21071 20.4142 5.58579C20.7893 5.96086 21 6.46957 21 7M3 7V17C3 17.5304 3.21071 18.0391 3.58579 18.4142C3.96086 18.7893 4.46957 19 5 19H19C19.5304 19 20.0391 18.7893 20.4142 18.4142C20.7893 18.0391 21 17.5304 21 17V7M3 7L12 13L21 7"
                    stroke="#B06EF7"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
                <ParagraphLargeStyle>
                  <a href="mailto:jwalag@innovationshub.pl">
                    jwalag@innovationshub.pl
                  </a>
                </ParagraphLargeStyle>
              </HelloContactTile>
              <HelloContactTile>
                <svg
                  className="svg-image"
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none">
                  <path
                    d="M5 4H9L11 9L8.5 10.5C9.57096 12.6715 11.3285 14.429 13.5 15.5L15 13L20 15V19C20 19.5304 19.7893 20.0391 19.4142 20.4142C19.0391 20.7893 18.5304 21 18 21C14.0993 20.763 10.4202 19.1065 7.65683 16.3432C4.8935 13.5798 3.23705 9.90074 3 6C3 5.46957 3.21071 4.96086 3.58579 4.58579C3.96086 4.21071 4.46957 4 5 4Z"
                    stroke="#B06EF7"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
                <ParagraphLargeStyle>
                  <a href="tel:+48668574097">+48 668 574 097</a>
                </ParagraphLargeStyle>
              </HelloContactTile>
              <HelloContactTile>
                <svg
                  className="svg-image"
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none">
                  <path
                    d="M8 11V16M8 8V8.01M12 16V11M16 16V13C16 12.4696 15.7893 11.9609 15.4142 11.5858C15.0391 11.2107 14.5304 11 14 11C13.4696 11 12.9609 11.2107 12.5858 11.5858C12.2107 11.9609 12 12.4696 12 13M4 6C4 5.46957 4.21071 4.96086 4.58579 4.58579C4.96086 4.21071 5.46957 4 6 4H18C18.5304 4 19.0391 4.21071 19.4142 4.58579C19.7893 4.96086 20 5.46957 20 6V18C20 18.5304 19.7893 19.0391 19.4142 19.4142C19.0391 19.7893 18.5304 20 18 20H6C5.46957 20 4.96086 19.7893 4.58579 19.4142C4.21071 19.0391 4 18.5304 4 18V6Z"
                    stroke="#B06EF7"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
                <ParagraphLargeStyle>
                  <a
                    href="https://www.linkedin.com/in/jan-walag"
                    target="_blank"
                    rel="noreferrer">
                    linkedin.com/in/jan-walag
                  </a>
                </ParagraphLargeStyle>
              </HelloContactTile>
            </FlexColumnContainer>
            <ParagraphLargeStyle>
              <Trans
                i18nKey="for-companies.hello-address"
                components={{
                  b: <b></b>,
                  a: (
                    <a
                      className="purple-link"
                      href="https://g.co/kgs/zaJA9jb"
                      target="_blank"
                      rel="noreferrer"></a>
                  )
                }}
              />
            </ParagraphLargeStyle>
            <PurpleButton
              as="a"
              target="_blank"
              rel="noreferrer"
              href="https://calendar.app.google/8aEj3y4G6Pbj4yVq6">{t`for-companies.hello-meet`}</PurpleButton>
          </HelloleftContainer>
          <RelativeContainer>
            <StaticImage
              className="circle-image circle2"
              src="../../../../images/for-companies/hi/circle2.webp"
              alt="image"
              placeholder="none"
              loading="lazy"
            />
            <StaticImage
              className="image"
              src="../../../../images/for-companies/hi/janek.webp"
              alt="image"
              placeholder="none"
              loading="lazy"
            />
          </RelativeContainer>
        </div>
      </HiContainer>
    </>
  );
};

export default HiSection;
